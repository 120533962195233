<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,customer,affiliate',status:'active,inactive,suspended'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<template v-for="(tabInfo, index) in tabs">
						<b-tab :key="index">
							<template slot="title">
								<router-link
									:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
									:to="{ name: tabInfo.name }"
									class="list-group-item btn text-left">
									{{ translate(tabInfo.translate_key) }}
								</router-link>
							</template>
							<template
								v-if="tabIndex === index"
								class="p-0">
								<router-view />
							</template>
						</b-tab>
					</template>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import DataFilter from '@/components/DataFilter';
import { SEARCH_USERS } from '@/config/endpoint';
import FiltersParams from '@/mixins/FiltersParams';
import { DATE_RANGES as dateRanges, V_CALENDAR_CONFIG as calendarConfig } from '@/settings/Dates';
import { Grids, CashPayments } from '@/translations';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'CashPayments',
	messages: [Grids, CashPayments],
	components: { DataFilter },
	mixins: [FiltersParams],
	data() {
		return {
			calendarConfig,
			dateRanges,
			endpoint,
			tabIndex: 0,
			tabs: [
				{
					name: 'CashPaymentsPending',
					translate_key: 'pending_tab',
				},
				{
					name: 'CashPaymentsApproved',
					translate_key: 'approved_tab',
				},
				// {
				// 	name: 'CashPaymentsRejected',
				// 	translate_key: 'rejected_tab',
				// },
			],
		};
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options });
		},
	},
};
</script>
